@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;500;600;900&display=swap');

body {
  font-family: 'Gothic A1', sans-serif;
  background-color: white;
  background: url('../media/subscribe/bg.svg');
  background-size: cover;
}

.card {
  margin-top: 1rem;
  border-radius: 10px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, .1);
}

@media (min-width: 768px) {
  .mt-md-10 {
    margin-top: 6rem !important;
  }
}

.card-header {
  border-radius: 10px 10px 0 0 !important;
  font-weight: 900;
  color: white;
  text-align: center;
  vertical-align: auto;
  border-bottom: none;
  background: linear-gradient(80.69deg, #09d8a2, #0fc6a1 18.41%, #1f9aa1 50.86%, #3953a0 79.43%, #4630a0 93%);
}

.card-header h3 {
  font-size: 27px;
  font-weight: 900;
  margin: 0.5rem !important;
  padding-top: 3px;
}

.card-title {
  padding-top: 25px;
  font-size: 17.88px;
  font-weight: 900;
  color: #484848;
}

.card-text {
  padding: 12px 25px 25px 25px;
  font-size: 12.62px;
  font-weight: 600;
  color: #666666;
}

.card-text img {
  margin-bottom: 1px;
}

.illustration {
  padding-bottom: 50px;
}

.form-control {
  border-radius: 30px;
  border-color: #e2e2e2;
  box-shadow: 0 0 4px rgba(0, 0, 0, .1);
}

.form-control:focus {
  border-color: transparent;
  box-shadow: 0 0 0 .25rem transparent;
}

.form-group>label {
  bottom: 38px;
  left: 15px;
  position: relative;
  background-color: white;
  color: #999999;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12.62px;
  font-weight: 600;
  transition: 0.2s;
  pointer-events: none;
}

.form-control:focus~label {
  color: #4630a0;
  font-size: 10px;
  bottom: 61px;
}

.form-control:valid~label,
.form-control:disabled~label {
  color: #999999;
  font-size: 10px;
  bottom: 61px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white !important;
}

.form-group>span {
  bottom: 40px;
  right: 15px;
  position: relative;
  padding: 5px;
  color: #999999;
  float: right;
  font-weight: 600;
  font-size: 16.26px;
}

span>img {
  margin: 0 5px 3px 5px;
}

.btn {
  font-weight: 600;
  margin-bottom: 25px;
  width: 100%;
  border-radius: 36px;
  background-color: #09D8A2;
  border-color: transparent;
}

.btn:hover {
  background-color: #3e45a0 !important;
  border-color: transparent;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #CCCCCC;
  border-color: #CCCCCC;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #3e45a0 !important;
  border-color: #3e45a0 !important;
  box-shadow: 0 0 0 .25rem #3e45a07c !important;
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #3e45a0 !important;
  border-color: #3e45a0 !important;
}

.text-primary {
  color: #09D8A2 !important;
}

.form-group {
  height: 54px;
  padding: 2px;
  margin-bottom: 30px;  
  border: 1px solid white;
  border-radius: 50px;
}

.form-group:focus-within {
  background-image: linear-gradient(to right, #3e45a0, #0068bb, #0086c6, #00a2c6, #00bbc1, #00c4af, #35cb92, #6bcf6e, #93c649, #b6ba28, #d7aa11, #f5961d);
}

.button-holder {
  margin: 0;
  padding: 0;
}

.btn-form {
  width: 33%;
}